'use client';

import styled from '@emotion/styled';
import createPath from '@local/router/createPath';
import { useEffect, useState } from 'react';
import { RouteName } from '@local/router/routes';
import Link from 'next/link';

const CookieContainer = styled('div')(() => ({
    position: 'fixed',
    bottom: 0,
    left: 0,
    width: '100%',
}));

const doesCookieExists = (cookieName: string, cookies: string) => {
    return cookies.split('; ').find((cookie) => cookie.startsWith(`${cookieName}=`)) ? true : false;
};

interface CookieBarProps {
    saveCookieHandler: () => void;
}

const CookieBar = ({ saveCookieHandler }: CookieBarProps) => {
    useEffect(() => {
        const bodyElement = document.getElementsByTagName('body')[0];

        const originalMargin = bodyElement.style.marginBottom;
        bodyElement.style.marginBottom = '120px';

        return () => {
            document.getElementsByTagName('body')[0].style.marginBottom = originalMargin;
        };
    });

    return (
        <CookieContainer className="notification is-info">
            <button className="delete" onClick={saveCookieHandler}></button>
            <p>
                We use only technologically necessary cookies. There are no marketing or analytical
                cookies. <Link href={createPath(RouteName.CookiePolicy)}>See the details.</Link>
            </p>
            <button className="button is-primary mt-4" onClick={saveCookieHandler}>
                OK
            </button>
        </CookieContainer>
    );
};

const CookieConsent = () => {
    const cookieName = 'gridCookieConsentV1';

    const [isCookieBarVisible, setIsCookieBarVisibible] = useState<boolean>(false);

    useEffect(() => {
        setIsCookieBarVisibible(!doesCookieExists(cookieName, document?.cookie ?? ''));
    }, []);

    const saveCookieHandler = () => {
        const isSecure = window.location.protocol === 'https:';

        document.cookie = `${cookieName}=1; max-age=31536000; ${
            isSecure ? 'secure; ' : ''
        }samesite=strict;`;

        setIsCookieBarVisibible(false);
    };

    if (!isCookieBarVisible) {
        return null;
    }

    return <CookieBar saveCookieHandler={saveCookieHandler} />;
};

export default CookieConsent;
