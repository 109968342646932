import(/* webpackMode: "eager" */ "/var/www/html/apps/grid-battles/src/app/globals.scss");
import(/* webpackMode: "eager" */ "/var/www/html/apps/grid-battles/src/components/CookieConsent.tsx");
import(/* webpackMode: "eager" */ "/var/www/html/node_modules/@fontsource/ubuntu/300.css");
import(/* webpackMode: "eager" */ "/var/www/html/node_modules/@fontsource/ubuntu/400.css");
import(/* webpackMode: "eager" */ "/var/www/html/node_modules/@fontsource/ubuntu/500.css");
import(/* webpackMode: "eager" */ "/var/www/html/node_modules/@fontsource/ubuntu/700.css");
import(/* webpackMode: "eager" */ "/var/www/html/node_modules/@fontsource/ubuntu/300-italic.css");
import(/* webpackMode: "eager" */ "/var/www/html/node_modules/@fontsource/ubuntu/400-italic.css");
import(/* webpackMode: "eager" */ "/var/www/html/node_modules/@fontsource/ubuntu/500-italic.css");
import(/* webpackMode: "eager" */ "/var/www/html/node_modules/@fontsource/ubuntu/700-italic.css");
import(/* webpackMode: "eager" */ "/var/www/html/node_modules/next/dist/client/link.js")