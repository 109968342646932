export enum RouteName {
    Homepage = 'homepage',
    Scenarios = 'scenarios',
    LearningScenarios = 'learning_scenarios',
    /* ChangeEmail = 'change_email', */
    /* ChangeForgottenPassword = 'change_forgotten_password', */
    /* ChangePassword = 'change_password', */
    /* ChangeUsername = 'change_username', */
    /* CreateLobby = 'create_lobby', */
    /* EnterLobby = 'enter_lobby', */
    /* ForgottenPassword = 'forgotten_password', */
    /* ForgottenPasswordEmailSent = 'forgotten_password_email_sent', */
    /* GameDetail = 'game', */
    /* InvalidLobbyInvitation = 'invalid_lobby_invitation', */
    /* Lobbies = 'lobbies', */
    /* LobbyDetail = 'lobby', */
    /* Login = 'login', */
    /* Logout = 'logout', */
    /* MyAccount = 'my_account', */
    /* MyOpenGames = 'my_open_games', */
    /* Registration = 'registration', */
    CreateGameAgainstComputer = 'create_game_against_computer',
    GameAgainstComputer = 'game_against_computer',
    CookiePolicy = 'cookie_policy',
    DataProtection = 'data_protection',
    /* AdminLosTest = 'admin_los_test', */
    /* AdminPathfinding = 'admin_pathfinding', */
    /* AdminScenarioEditor = 'scenario_editor', */
    /* AdminNewScenario = 'new_scenario', */
    /* AdminEditScenario = 'edit_scenario', */
    /* AdminProbability = 'admin_probability', */
    /* AdminAgent = 'admin_agent,', */
}

const routes = [
    {
        name: RouteName.Homepage,
        path: '/',
    },
    {
        name: RouteName.Scenarios,
        path: '/scenarios',
    },
    {
        name: RouteName.LearningScenarios,
        path: '/learning-scenarios',
    },
    /* { */
    /*     name: RouteName.ChangeEmail, */
    /*     path: '/change-email', */
    /* }, */
    /* { */
    /*     name: RouteName.ChangeForgottenPassword, */
    /*     path: '/change-forgotten-password/:hash', */
    /* }, */
    /* { */
    /*     name: RouteName.ChangePassword, */
    /*     path: '/change-password', */
    /* }, */
    /* { */
    /*     name: RouteName.ChangeUsername, */
    /*     path: '/change-username', */
    /* }, */
    /* { */
    /*     name: RouteName.CreateLobby, */
    /*     path: '/create-lobby', */
    /* }, */
    /* { */
    /*     name: RouteName.AdminEditScenario, */
    /*     path: '/edit-scenario/:scenarioId', */
    /* }, */
    /* { */
    /*     name: RouteName.EnterLobby, */
    /*     path: '/enter-lobby/:lobbyId/:invitationHash', */
    /* }, */
    /* { */
    /*     name: RouteName.ForgottenPassword, */
    /*     path: '/forgotten-password', */
    /* }, */
    /* { */
    /*     name: 'forgotten_password_email_sent', */
    /*     path: '/forgotten-password-email-sent', */
    /* }, */
    /* { */
    /*     name: RouteName.GameDetail, */
    /*     path: '/game/:gameId', */
    /* }, */
    /* { */
    /*     name: RouteName.InvalidLobbyInvitation, */
    /*     path: '/invalid-lobby-invitation', */
    /* }, */
    /* { */
    /*     name: RouteName.Lobbies, */
    /*     path: '/lobbies', */
    /* }, */
    /* { */
    /*     name: RouteName.LobbyDetail, */
    /*     path: '/lobby/:lobbyId', */
    /* }, */
    /* { */
    /*     name: RouteName.Login, */
    /*     path: '/login', */
    /* }, */
    /* { */
    /*     name: RouteName.Logout, */
    /*     path: '/logout', */
    /* }, */
    /* { */
    /*     name: RouteName.MyAccount, */
    /*     path: '/my-account', */
    /* }, */
    /* { */
    /*     name: RouteName.MyOpenGames, */
    /*     path: '/my-games', */
    /* }, */
    /* { */
    /*     name: RouteName.AdminNewScenario, */
    /*     path: '/new-scenario', */
    /* }, */
    /* { */
    /*     name: RouteName.Registration, */
    /*     path: '/register', */
    /* }, */
    {
        name: RouteName.CreateGameAgainstComputer,
        path: '/computer/create-game',
    },
    {
        name: RouteName.GameAgainstComputer,
        path: '/computer/game/:scenarioId',
    },
    /* { */
    /*     name: RouteName.AdminScenarioEditor, */
    /*     path: '/scenario-editor', */
    /* }, */
    /* { */
    /*     name: RouteName.AdminLosTest, */
    /*     path: '/admin/los-test', */
    /* }, */
    /* { */
    /*     name: RouteName.AdminPathfinding, */
    /*     path: '/admin/pathfinding', */
    /* }, */
    /* { */
    /*     name: RouteName.AdminProbability, */
    /*     path: '/admin/probability', */
    /* }, */
    /* { */
    /*     name: RouteName.AdminAgent, */
    /*     path: '/admin/agent', */
    /* }, */
    {
        name: RouteName.CookiePolicy,
        path: '/cookie-policy',
    },
    {
        name: RouteName.DataProtection,
        path: '/data-protection',
    },
];

export default routes;
