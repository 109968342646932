import routes, { RouteName } from './routes';

function createPath(routeName: RouteName, params: { [key in string]: string | number } = {}) {
    let path = null;

    path = routes.find((r) => r.name === routeName)?.path ?? null;

    if (!path) {
        return '/404-not-found';
    }

    const sortedKeys = Object.keys(params).sort((a, b) => {
        return b.length > a.length ? 1 : -1;
    });

    const queryParams: { [key in string]: string } = {};

    for (let i = 0; i < sortedKeys.length; i++) {
        const keyName = sortedKeys[i];

        if (!path.match(`:${keyName}`)) {
            queryParams[keyName] = `${params[keyName]}`;
        }

        path = path.replace(`:${keyName}`, `${params[keyName]}`);
    }

    if (Object.keys(queryParams).length) {
        const search = new URLSearchParams(queryParams);

        path = `${path}?${search.toString()}`;
    }

    return path;
}

export default createPath;
